import axios from "axios";
import {Storage} from "@/Services/Storage";

export default {
    name: 'Expenses',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        searchExpenseAccounts: async (context, accountName) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `chartOfAccounts/${storeDB}/searchExpenseAccounts?accountName=` + accountName)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        searchJobCards :async (context , payload) => {
            return axios.get(ticketApi+`ticket/basicSearch?search=`+payload)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        ExpenseCategorys: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `expenseAccounts/${storeDB}/getAll?archived=${archived}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        searchExpenses: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `chartOfAccounts/${storeDB}/searchExpenseAccounts?accountName=` +  "")
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        CreateOtherInvoice: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(DeliveryNote +`purchaseOrder/${storeDB}/createOrder`,payload)
                .catch(err=>errorHandler.tomcatError(err));
        },

        captureExpense: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.User = user.Username;
            return axios.post(backOfficeTomcatApi+`Payments/${storeDB}/ExpensePayment`,payload)
                .catch(err=>errorHandler.tomcatError(err));
        }
    }
}
