import axios from "axios";
import {Storage} from "@/Services/Storage";

export default {
    name: 'cashups',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        searchCashups: async (context, params) => {
            params = {
                ...params,
                ...context.getters.getGlobalDateRange
            }
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(onlineSalesTomcatApi + `cashup/${storeDB}/search`, {params})
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        searchStoreCashups: async (context, params) => {
            params = {
                ...params,
                ...context.getters.getGlobalDateRange
            }
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(onlineSalesTomcatApi + `cashup/${storeDB}/searchStoreCashups`, {params})
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        finalizeCashups: async (context, cashupNumbers) => {
            let pc = await Storage.getPCName();
            let user = await Storage.getCurrentUser();
            user = user.Username;
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(onlineSalesTomcatApi + `cashup/${storeDB}/performStoreCashup`, {
                    cashupNumbers, pc, user
                }).then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        printStoreCashup: async (context, cashupNumber) => {
            let DB = await Storage.getCurrentStoreDB();
            DB = window.btoa(DB);
            $utils.showLoading();
            return axios
                .get(
                    `${analyticsDocApi}cash-up/export/pdf-report/store-tender-cash-up/${cashupNumber}`,
                    {
                        headers: { DB },
                        responseType: 'blob', // Ensures the response is treated as a file (binary data)
                    }
                )
                .then((response) => {
                    // Create a URL for the blob
                    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = fileUrl;

                    // Set the filename for the downloaded file
                    link.setAttribute('download', `cashup-report-${cashupNumber}.pdf`);

                    // Append the link to the body, click it to trigger download, and then remove it
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch((error) => errorHandler.tomcatError(error)).finally(()=>$utils.hideLoading());
        }
    }
}
