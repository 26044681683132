    import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHouse, faHeartCircleCheck, faHeartCirclePlus, faBarcode, faMagnifyingGlass, faPlus, faMinus, faBars, faAngleRight, faArrowLeft, faDeleteLeft,faUser,
    faCartShopping, faPaperPlane, faClose, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,faCodeFork,
    faReceipt, faGear, faXmark, faChevronRight, faArrowRightFromBracket, faCreditCard, faMoneyBill, faAnglesUp,faAnglesDown,
    faChevronUp,faChevronDown,faPercentage,faCheckCircle,faMobileAlt,faLaptop,
    faBowlFood,faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faUserPlus, faHandHoldingDollar, faCoins, faArrowRotateLeft,
    faGears, faMoneyBills, faArrowTrendDown, faRotateLeft, faPrint, faTrash,faTable,faSave,faChartLine,faUpload,faFolderTree,faCancel,
    faCommentsDollar,faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple,faHandHoldingDroplet,faEnvelope,faExchange,faSliders,
    faArrowDown,faArrowUp,faShuffle,faArrowRight,faChartBar,faEye,faMoneyCheck,faExclamationCircle , faBell , faUserGear,
    faBusinessTime,faFingerprint,faImage,faCheck,faFileInvoice,faWallet,faClipboard,faCompass,
    faTriangleExclamation,faCalendarCheck,faClipboardList,faDownload,faComments,faFileArrowUp,faLink

} from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faHouse, faHeartCircleCheck, faHeartCirclePlus, faBarcode,faMobileAlt,faLaptop,faMagnifyingGlass,faRotateLeft, faClose, faPlus, faMinus, faBars, faAngleRight, faArrowLeft,faUser,
    faChevronUp,faChevronDown,faPercentage,faFingerprint,faCheckCircle,
    faAnglesUp,faAnglesDown, faDeleteLeft, faCartShopping, faPaperPlane, faCashRegister, faBoxes, faLock, faBasketShopping,
    faBoxesStacked, faReceipt, faGear, faArrowRightFromBracket, faXmark, faChevronRight, faCreditCard, faMoneyBill,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus,faBell,
    faArrowRotateLeft,faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave, faArrowsRotate,
    faLayerGroup,faCodeFork,faUsers, faHandHoldingDollar, faCoins, faUserPlus,faChartLine,faUpload,faFolderTree,faUserGear,
    faCommentsDollar,faBowlFood,faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple, faHandHoldingDroplet,faEnvelope,
    faExchange,faCancel,faSliders,faArrowDown,faArrowUp,faShuffle,faArrowRight,faChartBar,faEye,faMoneyCheck,
    faExclamationCircle ,faBusinessTime , faImage , faCheck, faFileInvoice,faWallet,faClipboard,faCompass,
    faTriangleExclamation,faCalendarCheck,faClipboardList,faDownload,faComments,faFileArrowUp,faLink

)

