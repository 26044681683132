import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
    name: 'stock-linking',
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        addInstructionToRecipe: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(`${onlineSalesTomcatApi}stock/recipe/${storeDB}/${payload.main}`, payload.payload)
                .then(({data})=>data)
                .catch(err => errorHandler.tomcatError(err))
        },
        deleteInstructionFromRecipe: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(`${serverApi}stock/recipe/${storeDB}/${payload.main}/${payload.link}`)
                .then(({data})=>data)
                .catch(err => errorHandler.tomcatError(err))
        },
        getIngredients: async (context,plu)=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(`${onlineSalesTomcatApi}stock/recipe/${storeDB}/${plu}`)
                .then(({data})=>data)
                .catch(err => errorHandler.tomcatError(err))
        },
        linkItemsByCase: async (context,request)=> {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(`${onlineSalesTomcatApi}stock/cases/${storeDB}/${request.parent}`,request.payload)
                .then(({data})=> data)
                .then(()=>context.dispatch(`recalculateItemCost`,request.payload.child))
                .catch(err => errorHandler.tomcatError(err));
        },
        recalculateItemCost:async (_, plu) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(onlineSalesTomcatApi + `stock/${storeDB}/recalculateItemCost?plu=` + plu)
        },
        getCaseLink: async (context,plu)=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(`${onlineSalesTomcatApi}stock/cases/${storeDB}/${plu}`)
                .then(({data})=>data)
                .catch(err => errorHandler.tomcatError(err))
        },

        getCaseHeirachyByItem: async (context,plu)=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(`${onlineSalesTomcatApi}stock/cases/${storeDB}/getCaseLinkByItem?plu=`+plu)
                .then(({data})=>data)
                .catch(err => errorHandler.tomcatError(err))
        },
        deleteCaseLink: async (context,plu)=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(`${onlineSalesTomcatApi}stock/cases/${storeDB}/${plu}`)
                .then(({data})=>data)
                .catch(err => errorHandler.tomcatError(err))
        },

        produceStockItemDialog : async (context,payload) => {
            return Swal.fire({
                title: `Production`,
                html: `<span>Description : ${payload.Description}</span>
               <span>Current QTY : ${payload.QTY}</span>
               <input type="number" step="0.001" id="production-qty" class="swal2-input" placeholder="Production Qty" style="max-width: 70%">`,
                inputAutoTrim: true,
                showCancelButton: true,
                confirmButtonText: 'Produce',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let productionQty = Swal.getPopup().querySelector('#production-qty').value
                    if (!productionQty) return Swal.showValidationMessage(`Production Qty is required`)

                    let user = await Storage.getCurrentUser();

                    let obj = {
                        PLU: payload.PLU,
                        User : user.Username,
                        ProducedQTy : Number(productionQty),
                    };
                    return context.dispatch(`produceStockItem`, obj)
                        .then(()=>productionQty)
                        .catch(error => Swal.showValidationMessage(error))
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    return responses.showSuccess(result.value+` Of ${payload.Description} Produced`);
                }
            })
        },
        produceStockItem : async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(backOfficeTomcatApi + `stockUtils/${storeDB}/Manufacture`, payload)
                .then(() => context.commit(`clearStock`))
                .catch(err => errorHandler.tomcatError(err,true))
        }
    }
}
