<template>
  <div>
    <div v-if="hasNotes">
      <div v-if="publicNote" id="pills-notes" aria-labelledby="pills-notes-tab" class="tab-pane fade show active"
           role="tabpanel">
        <TicketNotesGroup :internal="false" :ticket-id="ticketID"/>
      </div>
      <hr>
    </div>
    <CreateEditNote ref="editor" :ticket-id="ticketID"></CreateEditNote>
  </div>
</template>
<script>
import TicketNotesGroup from "@/components/notes/TicketNotesGroup";
import CreateEditNote from "@/components/notes/CreateEditNote";

export default {
  name: 'TicketNotesList',
  components: {CreateEditNote, TicketNotesGroup},
  props: {
    ticketID: {required: true}
  },
  computed: {
    hasNotes() {
      return !(!this.$store.getters.getCurrentNotes.length)
    },
    internalNote() {
      return this.$store.getters.getCurrentNotes.filter(note => note.internal)
    },
    publicNote() {
      return this.$store.getters.getCurrentNotes.filter(note => !note.internal)
    },
  },
  methods: {
    addNoteToTicket() {
      return this.$store.dispatch('createNoteView', this.ticketID)
    }, edit(note) {

    }, setInternal(internal) {
      this.$refs.editor.updateInternalStatus(internal)
    }
  },
}
</script>
<style lang="scss" scoped>
.note-count-label {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
  font-weight: bold;
  line-height: 20px;
  margin-right: 5px;
  text-align: center;
  width: 20px;
  font-size: 12px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  .note-count-label {
    background: white;
    color: rgb(11, 102, 255);
  }
}

.nav-pills .nav-link {
  .note-count-label {
    color: white;
    background: rgb(11, 102, 255);
  }
}

.primary-btn {
  min-width: 90px;
}

hr {
  border: 1px #1c1c1c solid;
}
</style>