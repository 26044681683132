import {Storage} from "@/Services/Storage";
import axios from "axios";
import queryStringify from "qs-stringify";

export default {
    name: 'loyalty',
    state: {
        hasLoyalty : false,
        loyalty: {},
    },
    getters: {
        getHasLoyalty:(state) => state.hasLoyalty,
        getLoyaltyDetails: (state) => state.loyalty,
    },
    mutations: {
        setLoyaltyDetails: (state , data) => state.loyalty = data,
        setHasLoyalty:async (state , data) => {
            if (data) await Storage.setHasLoyalty(data)
            else data = await Storage.getHasLoyalty()
            state.hasLoyalty = data
        },
    },
    actions: {
        isLoyaltyActivated:async (context)=>{
            let store = await Storage.getCurrentStore()
            if (!store) return;
            let DB = store.StoreDB;
            return axios.get(loyaltyApi+`${DB}/registered`)
                .then(({data}) =>context.commit(`setHasLoyalty`,data.success))
                .catch(err=>errorHandler.tomcatError(err))
        },
        checkLoyaltyBarcode:async (context,barcode)=>{
            let store = await Storage.getCurrentStore()
            if (!store) return;
            let DB = store.StoreDB;
            return axios.get(loyaltyApi+`${DB}/customer/${barcode}`)
                .then(({data}) => {
                    if (data.success){
                        return context.commit(`setLoyaltyDetails`, data.data)
                    }
                    return responses.showWarning(data.message);
                }).catch(err=>errorHandler.tomcatError(err))
        },

        searchLoyaltyCustomers:async (context,query)=>{
            let store = await Storage.getCurrentStore()
            if (!store) return;
            let DB = store.StoreDB;
            return axios.get(loyaltyApi+`${DB}/customer/seach?q=${query}`)
                .then(({data}) => {
                    if (data.success){
                        return data.data;
                    }
                    return responses.throwErr(data.message);
                }).catch(err=>errorHandler.tomcatError(err))
        },

        createLoyaltyCustomer: async (context , payload) => {
            let store = await Storage.getCurrentStore()
            if (!store) return;
            let DB = store.StoreDB;
            return axios.post(loyaltyApi+`${DB}/register/customer`,payload)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        }
    }
}
