<template>
  <div class="notes-list-group">
    <div class="notes-list">
      <TicketNoteListItem v-for="note in notes" :key="`note-${note.id}`" :note="note" :ticket-id="ticketId"/>
    </div>
  </div>
</template>
<script>

import TicketNoteListItem from "@/components/notes/TicketNoteListItem";

export default {
  name: 'TicketNotesGroup',
  components: {TicketNoteListItem},
  computed: {
    notes() {
      return this.$store.getters.getCurrentNotes
          .filter(note => note.internal === this.internal)
          .filter(note => note.replyTo === 0)
    }
  },
  methods: {},
  props: {
    internal: {
      type: Boolean,
      required: true,
    },
    ticketId: {
      type: Number,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.notes-list-group {
  display: flex;
  flex-direction: column;

  .notes-list {
    display: flex;
    flex-direction: column;
    transition: ease-in-out;
    transition-duration: 300ms;
    gap: 5px;
  }

  .view-more-tickets-box {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0.9331933456976541) 7%, rgba(0, 212, 255, 0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.9331933456976541) 7%, rgba(0, 212, 255, 0) 100%);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9331933456976541) 7%, rgba(0, 212, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#00d4ff", GradientType=1);
    height: 50px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .view-more-btn {
      background: none;
      color: rgba(11, 102, 255, 0.8);
      font-weight: 500;
      padding: 0 10px;
      height: 30px;
      width: 128px;
      transition: ease-in-out;
      transition-duration: 300ms;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:active, &:hover {
        font-weight: 600;
        color: rgba(11, 102, 255, 1);
      }

      &:focus {
        border: 2px solid rgba(11, 102, 255, 1);
        border-radius: 5px;
      }
    }
  }

}

hr {
  border: 1px #1c1c1c solid;
}
</style>