import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import loyaltyModule from "@/store/modules/Sale/loyalty-module";
import cashupsModule from "@/store/modules/finance/cashups-module";
import stockModule from "./modules/stock/stock-module";
import vatCalculator from "./modules/finance/vat_calculator";
import specialsModule from "./modules/stock/specials-module";
import stockReceiveModule from "./modules/stock/stock-received-module";
import supplierModule from "./modules/supplier-module";
import debtorModule from "./modules/Accounts/debtor-module";
import userModule from "./modules/employee/user-module";
import emailModule2 from "@/store/modules/email-module";
import securitiesModule from "./modules/employee/securities-module";
import stockCategoryModule from './modules/stock/stockcategory-module';
import taxModule from './modules/tax-module';
import emailModule from './modules/Accounts/email-module';
import timeModule from './modules/time-module';
import reportsModule from './modules/report-module';
import stockTakeModule from './modules/stock/stock-take-module'
import stockLinkingModule from "./modules/stock/stock-linking-module";
import stockTransferModule from './modules/stock/stock-transfer-module'
import safeModule from './modules/finance/safes-module'
import expenseModule from './modules/finance/expense-module'
import cookingInstruction from './modules/stock/cooking-instructions-module'
import billingModule from './modules/billing-module'
import mainDebtorModule from './modules/Accounts/main-debtor-module'
import floatModule from './modules/employee/float-module'
import currencyModule from './modules/finance/currency-module'
import paymentMethodsModule from './modules/finance/payment-methods-module'
import settingsModule from './modules/settings-module'
import coaModule from './modules/finance/chart-of-accounts'
import deliveryNotesModule from './modules/Accounts/delivery-notes-module'
import ticketModule from './modules/ticket-module'
import router from "../router";
import {Storage} from "@/Services/Storage";
import Swal from "sweetalert2";
import CashupsModule from "@/store/modules/finance/cashups-module";
import ticket_notes_module from "@/store/modules/ticket_notes_module";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        loading: false,
        isUpdateAvailable: false,
        totalExpenseCost: 0, // Holds the total expense
        production: false,
        otherExpenses: [],
        allStores: [],
        currentStore: null,
        isSmartInvoicing: false,
    },
    getters: {
        getTotalExpenseCost(state) {
            return state.totalExpenseCost;
        },
        getOtherExpenses: (state) => state.otherExpenses,
        currentDate: () => {
            let date = new Date();
            return date.toISOString().split('T')[0];
        },
        getUpdateAvailability: (state) => state.isUpdateAvailable,
        getSmartInvoicingStatus: (state) => state.isSmartInvoicing,
        getProduction: (state) => state.production,
        currentTime: () => {
            let date = new Date();
            let d = date.toISOString().split('T')[0]
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let t = hour + ':' + minute;
            return d + `T` + t;
        },
        getLoadingState: (state) => state.loading,
        getAllStores: (state) => state.allStores,
        getCurrentStore: (state) => state.currentStore,
        detectOS() {
            let platform = navigator.platform.toLowerCase();
            let userAgent = navigator.userAgent.toLowerCase();
            if (platform.includes('win')) {
                return 'Windows';
            } else if (/android/.test(userAgent)) {
                return 'Android';
            } else if (platform.includes('mac') || userAgent.includes('mac os')) {
                return 'MacOS';
            } else if (platform.includes('linux')) {
                return 'Linux';
            } else if (/iphone|ipad|ipod/.test(userAgent)) {
                return 'iOS';
            } else {
                return 'Unknown OS';
            }
        },
    },
    mutations: {
        setTotalExpenseCost(state, total) {
            state.totalExpenseCost = total;
        },
        setUpdateAvailability: (state, payload) => state.isUpdateAvailable = payload,
        setSmartInvoicingStatus: (state, payload) => state.isSmartInvoicing = payload,
        setLoadingState: (state, payload) => state.loading = payload,
        setMobileUser: (state, payload) => Storage.setMobileUser(payload),
        setAllStores: async (state, payload) => {
            if (payload) await Storage.setAllStores(payload)
            else payload = await Storage.getAllStores();
            state.allStores = payload ? payload : [];
        },
        SET_OTHER_EXPENSES(state, expenses) {
            state.otherExpenses = expenses;
        },
        ADD_OTHER_EXPENSE(state, expense) {
            state.otherExpenses.push(expense);
        },

        setCurrentStore: async (state, payload) => {
            if (payload) await Storage.setCurrentStore(payload)
            else payload = await Storage.getCurrentStore();
            state.currentStore = payload;
        },
    },
    actions: {

        refreshState: async (context) => {
            let validate = await context.dispatch(`validatePCRegistration`);
            if (!validate.success) {
                return responses.throwErr(validate.message)
                    .then(() => {
                        return context.dispatch(`clearStoreState`)
                            .then(async () => {
                                let deviceID = await Storage.getDeviceID();
                                localStorage.clear();
                                await Storage.setDeviceID(deviceID)
                                try {
                                    await this.$router.push({name: 'login'})
                                } catch (e) {
                                }
                                return window.location.reload(true);
                            });
                    });
            }
            let promiseArray = [
                context.commit(`setAllStores`),
                context.commit(`setCurrentStore`),
                context.commit(`setCurrentUser`),
            ];
            let storeDB = await Storage.getCurrentStoreDB();
            if (storeDB) {
                promiseArray.push(
                    context.dispatch(`getExpiryDateByCustomerID`),
                    context.dispatch(`updateToLatestVersion`),
                    context.dispatch(`getAllTaxLabels`),
                    context.dispatch(`getAllUsers`),
                    context.dispatch(`getAllSecurities`),
                    context.dispatch(`getCategories`),
                    context.dispatch(`getAllCurrencies`),
                    context.dispatch(`checkSmartInvoicing`),
                    context.dispatch(`getBaseCurrency`),
                    context.dispatch(`isLoyaltyActivated`),
                    context.dispatch(`getKazangDetails`),
                    context.dispatch(`getAllPaymentMethods`),
                    context.dispatch(`getZraSupportedCountries`)
                )
            }
            return Promise.all(promiseArray).catch(err => console.error(err));
        },
        mobileUserLogin: (context, payload) => {
            return axios.post(backOfficeTomcatApi + `mobileUser/login`, payload)
                .then(({data}) => {
                    return Promise.all([
                        context.commit(`setMobileUser`, payload),
                        context.commit(`setAllStores`, data)
                    ])
                }).catch(err => errorHandler.tomcatError(err));
        },
        setOtherExpenses({ commit }, expenses) {
            console.log("Setting otherExpenses in Vuex:", expenses); // Log to confirm
            commit("SET_OTHER_EXPENSES", expenses);
        },
        addOtherExpense({ commit }, expense) {
            commit("ADD_OTHER_EXPENSE", expense);
        },
        updateTotalExpenseCost({ commit }, total) {
            commit('setTotalExpenseCost', total);
        },
        loginToStore: async (context, storePayload) => {
            await context.commit('setCurrentStore', storePayload);
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `other/getExpiryDate?Database=` + storeDB)
                .then(async ({data}) => {
                    let expireDate = new Date(data);
                    let today = new Date();
                    if (expireDate < today) {
                        // return responses.showWarning(`System Expired!`);
                        return Swal.fire({
                            title: `System Expired ! `,
                            icon: `error`,
                            html: `<p>Please make payment to continue</p>`,
                            showCancelButton: true,
                            confirmButtonText: `Continue`,
                            showLoaderOnConfirm: true,
                            preConfirm: async () => {
                                return context.dispatch(`getPaymentRoute`);
                            }
                        }).then(() => {
                            return Swal.fire({
                                title: `System Expired ! `,
                                icon: `info`,
                                html: `<p>You will be logged out</p> 
                                       <span>You can login again after making payment</span>
                                      `,
                            }).then(() => {
                                return context.dispatch(`clearStoreState`)
                                    .then(async () => {
                                        let deviceID = await Storage.getDeviceID();
                                        localStorage.clear();
                                        await Storage.setDeviceID(deviceID)
                                        try {
                                            await this.$router.push({name: 'login'})
                                        } catch (e) {
                                        }
                                        return window.location.reload(true);
                                    });
                            });
                        })
                    }
                    return context.dispatch(`refreshState`)
                        .then(() => router.push({name: 'userLogin'}));
                });
        },

        clearStoreState: async (context) => {
            let mobileUser = await Storage.getMobileUser();
            let deviceID = await Storage.getDeviceID();
            let stores = await Storage.getAllStores();
            localStorage.clear();
            await Storage.setDeviceID(deviceID);
            context.commit(`setAllStores`, stores);
            context.commit(`setMobileUser`, mobileUser)
        },

        openFranchisePlatform: async (context, franchiseRoute = {name: 'home'}) => {
            const franchisePlatform = `https://chrilantech.com/Franchise/`;
            let authUser = await Storage.getMobileUser();
            let payload = {
                route: franchiseRoute,
                auth: authUser
            }
            return openUrl(franchisePlatform + `?auth=` + window.btoa(JSON.stringify(payload)));
        },
        printInvoice: async (context, invoice) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(printApi + `Quotes/${storeDB}/invoice/${invoice.invoice}?PC=Online&reprint=${invoice.reprint}`)
        },

        printQuote: async (context, saleNumber) => {
            let obj = {
                storeDB: await Storage.getCurrentStoreDB(),
                saleNumber: saleNumber
            };
            return context.dispatch(`printQuoteDB`, obj);
        },

        printQuoteDB: async (context, obj) => {
            let storeDB = obj.storeDB;
            let saleNumber = obj.saleNumber;
            return openUrl(franchiseApi + `SaveTable/PrintQuote/${saleNumber}/${storeDB}`);
        },

        printPickingSlip: async (context, saleNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(franchiseApi + `SaveTable/PrintDelivery/${saleNumber}/${storeDB}`);
        },
        async devicecount() {
            try {
                this.storeDB = await Storage.getCurrentStoreDB();
                const response = await axios.get(subscriptionApi + `devices/getResetStatusByStore?DB=${this.storeDB}`);
                this.deviceCountData = response.data; // Update the data property with the fetched data
                console.log("Fetched data:", this.deviceCountData); // Log for debugging
            } catch (error) {
                console.error("Error fetching device count:", error);
            } finally {
            }
        },


        async validatePCRegistration(context) {
            try {
                // Fetch device count data
                await context.dispatch('devicecount');

                // Get the necessary details
                const deviceID = await Storage.getDeviceID();
                const store = await Storage.getCurrentStore();

                if (!store) {
                    return { success: true, message: `Success` };
                }

                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                const screenSize = window.innerWidth <= 768;
                const touchEnabled = "ontouchstart" in window || navigator.maxTouchPoints > 0;

                const mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
                const isMobile = mobileUserAgent || (screenSize && touchEnabled);

                // Store isMobile value in local storage
                localStorage.setItem('isMobile', JSON.stringify(isMobile));

                const obj = {
                    customerID: store.CustomerID,
                    deviceID: deviceID,
                    isMobile: isMobile,
                };


            // Validate PC Registration
            return axios.get(subscriptionApi + `devices/validatePCRegistration`, {params: obj})
                .then(() => {
                    return {success: true, message: "Success"}
                }).catch((err) => {
                    // Handle registration error
                    const errorMessage = `Registration Failed: \n${errorHandler.franchiseLoginError(err)}`;

                    // Show SweetAlert for error
                    return Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: errorMessage,
                        confirmButtonText: "OK",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Prompt for username input
                            Swal.fire({
                                title: 'Enter your UserName',
                                input: 'text',
                                inputLabel: 'Username',
                                showCancelButton: true,
                                confirmButtonText: 'Submit',
                                cancelButtonText: 'Cancel',
                                preConfirm: (username) => {
                                    if (!username) {
                                        Swal.showValidationMessage("Please enter a valid username.");
                                    } else {
                                        return username;
                                    }
                                },
                            }).then((usernameResult) => {
                                if (usernameResult.isConfirmed) {
                                    const username = usernameResult.value;
                                    console.log(`Username: ${username}`);

                                    // Show device count data
                                    Swal.fire({
                                        title: "Device Management",
                                        html: `
                                <p><strong>Number of Device Count Resets Remaining:</strong> ${this.deviceCountData.remainingResets}</p>
                                <p><strong>Number of Device Recounts Done:</strong> ${this.deviceCountData.resets}</p>
                            `,
                                        confirmButtonText: "Reset All",
                                    }).then(async (resetResult) => {
                                        if (resetResult.isConfirmed) {
                                            console.log(`Username before dispatch: ${username}`); // Add this to confirm the value
                                            await context.dispatch('devicecount2', username);
                                        }
                                    });
                                }
                            });
                        }
                    });
                });
            }catch (e) {

            }
        },
        async devicecount2(context, username) {
            try {
                // Ensure the username is provided
                console.log(username, "this is the username")
                if (!username) {
                    throw new Error("Username is required for resetting devices.");
                }

                this.storeDB = await Storage.getCurrentStoreDB();

                await axios
                    .post(subscriptionApi + "devices/resetDevices", {
                        user: username, // Use the provided username
                        DB: this.storeDB,
                    })
                    .then((response) => {
                        console.log("Fetched data:", response.data); // Log the actual response data

                        // Show success message
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "Device reset was successful!",
                            timer: 2000, // 2 seconds
                            showConfirmButton: false,
                        }).then(() => {
                            // Reload the page after success
                            location.reload();
                        });
                    })
                    .catch((err) => {
                        // Call your error handler
                        errorHandler.tomcatError(err);

                        // Show SweetAlert for the error
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "An error occurred while resetting devices.",
                        });
                    });
            } finally {
                // Optional cleanup logic
            }
        },

        updateToLatestVersion: async () => {
            let store = await Storage.getCurrentStore();
            return axios.put(franchiseTomcatApi + `version/${store.StoreDB}/updateToLatest`);
        },

        getAllDevices: async () => {
            let store = await Storage.getCurrentStore();
            return axios.get(franchiseTomcatApi + `settings/${store.StoreDB}/getAllPCs`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        getCustomerDetails: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            return axios.get(franchiseTomcatApi + `stores/getRegistrationDetails`, {
                params: {database: storeDB}
            }).then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },


        checkSmartInvoicing: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            let user = await Storage.getCurrentUser();
            return axios.get(franchiseTomcatApi + `GRV/${storeDB}/checkSmartInvoicing`)
                .then(({data}) => {
                    context.commit(`setSmartInvoicingStatus`, data)
                    let isLive = context.getters.getSmartInvoicingLive;
                    if (!data && isLive) {
                        return context.dispatch(`checkIfStoreDocsSubmitted`).then(() => {
                            let hasDocs = context.getters.getDocSubmissionStatus;

                            if (!hasDocs && !!user && router.currentRoute.name !== `userLogin` && router.currentRoute.name !== `login`) {
                                return Swal.fire({
                                    title: 'Register for Smart Invoicing Now',
                                    text: 'You are not registered for smart invoicing. Please register now.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    showCloseButton: true,
                                    allowOutsideClick: false,
                                    confirmButtonText: 'Register',
                                    cancelButtonText: 'Cancel'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        return router.push({name: 'smart-invoicing-setup'});
                                    }
                                });
                            }
                        })
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        },

        getAllPrinters: () => {
            return axios.get(hardwareApi + `utils/getPrinters`)
                .then(({data}) => data)
        },

    },
    modules: {
        stockModule,
        loyaltyModule,
        supplierModule,
        debtorModule,
        userModule,
        stockCategoryModule,
        stockReceiveModule,
        floatModule,
        paymentMethodsModule,
        taxModule,
        timeModule,
        reportsModule,
        stockTakeModule,
        stockLinkingModule,
        securitiesModule,
        stockTransferModule,
        settingsModule,
        coaModule,
        safeModule,
        expenseModule,
        cookingInstruction,
        billingModule,
        mainDebtorModule,
        specialsModule,
        currencyModule,
        deliveryNotesModule,
        emailModule,
        emailModule2,
        ticketModule,cashupsModule,ticket_notes_module,

        vatCalculator
    }
})
