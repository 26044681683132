<template>
  <div class="d-flex flex-column">
    <h6 class="text-secondary">Clear note pad</h6>
    <button class="danger-btn" @click="clearCurrentNote">
      <font-awesome-icon icon="fa-solid fa-trash"/>
    </button>
<!--    <br>-->
    <button :disabled="loading || !note.Note" class="btn btn-success mt-3" style="background-color: #1d90dd" @click="saveNote">
      <span v-if="loading" aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
      <font-awesome-icon class="mr-2" icon="fa-solid fa-pencil"/>
      {{ note.edit ? 'Save note' : 'Add Note' }}
    </button>

    <div v-if="note.internal" class="row p-0 mt-2 mb-2">
      <label for="user_replying_to">User Replying To</label>
      <v-select id="user_replying_to" v-model="note.employee" :options="allUsers" :reduce="type => type.id" label="username">
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events"/>
        </template>
      </v-select>
    </div>

    <vue-editor v-model="note.Note" :editor-toolbar="toolbar"></vue-editor>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CreateEditNote',
  async beforeMount() {
    await this.fetchAllUsers();
  },
  computed: {
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },

    note() {
      return this.$store.getters.getEditNote;
    }
  },
  data() {
    return {
      allUsers: [], // Store users here
      loading: false,
      toolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }]
      ]
    };
  },
  props: {
    ticketId: {
      type: Number,
      required: true
    }
  },
  methods: {
    async fetchAllUsers() {
      try {
        let response = await axios.get('https://lna.abc.co.zm/TicketApp/support/getActiveSupportStaff');
        this.allUsers = response.data.filter(user => user.id !== this.currentUser.id);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    updateInternalStatus(internal) {
      this.clearCurrentNote();
      this.$store.commit('setInternalStatus', internal);
    },
    saveNote() {
      this.loading = true;
      if (!this.note.internal) {
        return Swal.fire({
          title: 'Are you sure?',
          text: "The Customer Will Receive This Message",
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, I am aware',
          cancelButtonText: 'Wait, no!',
          preConfirm: () => {
            return this.createUpdateNote().then(() => {
              this.$store.dispatch('clearEditNote');
            });
          }
        }).then((result) => {
          if (result.isConfirmed) {
            let text = this.note.edit ? `Edited` : `Added`;
            return Swal.fire(
                'Done!',
                `Note ${text} successfully`,
                'success'
            ).finally(() => this.loading = false);
          } else {
            this.loading = false;
          }
        });
      }
      if (!this.note.employee) {
        return responses.showWarning(`Who are you applying to?`)
            .finally(() => this.loading = false);
      }
      return this.createUpdateNote().then(() => {
        this.$store.dispatch('clearEditNote');
        let text = this.note.edit ? `Edited` : `Added`;
        return responses.showSuccess(`Note ${text} successfully`);
      }).finally(() => this.loading = false);
    },
    createUpdateNote() {
      let employee = this.note.employee;
      if (this.note.employee !== undefined) {
        employee = this.note.employee.id ? this.note.employee.id : this.note.employee;
      }
      if (this.note.edit) {
        return this.$store.dispatch('updateTicketNote', {
          id: this.note.id,
          Note: this.note.Note,
          employee: null,
          createduser: this.currentUser,
          internal: false,
          ticketid: Number(this.ticketId),
          replyTo: this.note.replyTo
        });
      }

      return this.$store.dispatch('addNoteToTicket', {
        Note: this.note.Note,
        createduser: this.currentUser,
        internal: this.note.internal,
        ticketid: Number(this.ticketId),
        replyTo: this.note.replyTo,
        employee: null
      });
    },
    clearCurrentNote() {
      return this.$store.commit('clearEditNote');
    }
  }
};
</script>

<style lang="scss" scoped>
.danger-btn{
  background-color: lightblue;
  border-radius: 5px;
}
.spinner-grow {
  margin-right: 10px;
}

.danger-btn {
  width: 50px;
  float: right;
}
</style>