import {Storage} from "../../../Services/Storage";
import axios from "axios";

export default {
    name: 'stock-received',
    state: {
        itemsToReceive: [],
        selectedSupplierCode : null,
    },
    getters: {
        getItemsToReceive: (state) => state.itemsToReceive,
        getSelectedSupplierCode : (state) => state.selectedSupplierCode
    },

    mutations: {
        setSelectedSupplierCode : (state, data) => state.selectedSupplierCode = data,
        setItemsToReceive : (state, data) => state.itemsToReceive = data,
        addToReceivedItems: (state, data) => {
            let plu = data.PLU
            let expire = data.expire;
            let check = state.itemsToReceive.find(item=>item.PLU === plu && item.expire === expire)
            if (check){
                data.receivedQty = data.receivedQty + check.receivedQty;
                state.itemsToReceive = state.itemsToReceive.filter(item => item.PLU !== plu || item.expire !== expire);
            }
            state.itemsToReceive.push(data)
        },
        clearGrvData: (state) => {
            state.itemsToReceive = [];
            state.selectedSupplierCode = null;
        },
        removeFromGRV: (state , item) => {
            let plu = item.PLU;
            let expire = item.expire;
            state.itemsToReceive = state.itemsToReceive.filter(item => item.PLU !== plu || item.expire !== expire);
        }
    },
    actions: {
        createGRV: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            let items = payload.items.map(item => {
                return {
                    PLU: item.PLU,
                    Description: item.Description,
                    qty: item.receivedQty,
                    expire: item.expire,
                    InclCost: item.unitCost,
                    selling: item.selling,
                    itemSeq: item.itemSeq,
                };
            });
            let data = {
                supplier: context.getters.getSelectedSupplierCode,
                purchaseOrder: payload.purchaseOrder,
                items: items,
                invoiceNumber: payload.invoiceNumber,
                user: user.Username,
                oldGrvNumber: payload.oldGrvNumber,
                pc: 'Online',
                otherExpenses: payload.otherExpenses, // Correctly reference otherExpenses from payload
                refund: payload.refund,
                isSmartInvoice: payload.isSmartInvoice,
                isSmartInvoiceImport: payload.isSmartInvoiceImport,
            };

            return axios.post(franchiseTomcatApi + `GRV/${storeDB}/CreateGRV`, data)
                .then(({ data }) => {
                    context.commit('clearGrvData');
                    return data;
                })
                .catch((err) => errorHandler.tomcatError(err));
        },
        updatePurchaseOrder: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            let ordernumber = payload.ordernumber;
            let responseArray = payload.responseArray.map(item => ({
                PLU: item.plu,
                Description: item.description,
                qty: item.qty,
                InclCost: item.cost,
            }));
            console.log(responseArray, "hello")
            let items = payload.items.map(item => ({
                PLU: item.PLU,
                Description: item.Description,
                qty: item.receivedQty,
                InclCost: item.unitCost,
            }));

            // Correct way to merge both arrays
            let combinedItems = [...items, ...responseArray];

            let data = {
                supplier: context.getters.getSelectedSupplierCode,
                items: combinedItems,  // Fixed: Properly merged array
                invoiceNumber: payload.invoiceNumber,
                deliveryDate: payload.deliveryDate,
                user: user.Username,
                pc: 'Online',
            };

            return axios.post(`${backOfficeTomcatApi}purchaseOrder/${storeDB}/update/${ordernumber}`, data)
                .then(() => context.commit('clearGrvData'))
                .catch((err) => errorHandler.tomcatError(err));
        },

        createPurchaseOrder: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            let items = payload.items.map(item=>{
               return {
                   PLU: item.PLU,
                   Description: item.Description,
                   qty: item.receivedQty,
                   InclCost : item.unitCost,
               }
            });
            let data = {
                supplier: context.getters.getSelectedSupplierCode,
                items: items,
                invoiceNumber: payload.invoiceNumber,
                deliveryDate: payload.deliveryDate,
                user: user.Username,
                pc: 'Online',

            }
            return axios.post(backOfficeTomcatApi + `purchaseOrder/${storeDB}`,data)
                .then(() => context.commit('clearGrvData')).catch((err) => errorHandler.tomcatError(err))
        },

        printGRV: async (context , grvNumber) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return context.dispatch(`printGRVDB`,{storeDB , grvNumber});
        },
        printGRVDB: async (context , obj) =>{
            let storeDB = obj.storeDB;
            return openUrl(franchiseApi+`PDFReport/GRVReports/${obj.grvNumber}/${storeDB}`)
        },

        makeGRVPayment :async (context , payload)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(backOfficeTomcatApi+`Payments/${storeDB}/makeGRVPayment`,payload)
                .then(()=>responses.showInfo(`Payment Successful`))
                .catch(err=>errorHandler.tomcatError(err));
        },
        getDeliveryNotes: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            payload = {
                ...context.getters.getGlobalDateRange,
                ...payload
            };

            // Convert payload to query parameters
            const queryParams = new URLSearchParams(payload).toString();

            return axios.get(`${DeliveryNote}api/delivery/search?${queryParams}&DB=${storeDB}`)
                .then(({ data }) => data)
                .catch(err => errorHandler.tomcatError(err));
        },

        getPurchaseOrders :async (context , payload)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            payload = {
                ...context.getters.getGlobalDateRange,
                ...payload
            }
            return axios.post(backOfficeTomcatApi+`purchaseOrder/${storeDB}/search`,payload)
                .then(({data})=>data)
                .catch(err=>errorHandler.tomcatError(err));
        },
        sendPurchaseOrder: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(`https://realtime.abc.co.zm:8443/Franchise_OnlineServer/emailrecord/${storeDB}/setEmailSettings`, payload)
                .catch((err) => errorHandler.tomcatError(err, false, `Could not Save Email settings`));
        },

        approvePurchaseOrder :async (context , orderNumber)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(backOfficeTomcatApi+`purchaseOrder/${storeDB}/approve/${orderNumber}`)
                .catch(err=>errorHandler.tomcatError(err));
        },

        editPurchaseOrder: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(backOfficeTomcatApi + `purchaseOrder/${storeDB}/updatePurchaseOrder`, payload)
                .catch(err=>errorHandler.tomcatError(err));
        },
        deleteDeliveryNote: async (context , orderNumber) =>{
            let storeDB = await Storage.getCurrentStoreDB();
             let user = await Storage.getCurrentUser();
            return axios.delete(DeliveryNote + `deliveryNotes/${storeDB}/${orderNumber}`, {
                params: {
                    user: user.Username
                }
            }).catch(err=>errorHandler.tomcatError(err));
        },
        deletePurchaseOrder: async (context , orderNumber) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            return axios.delete(backOfficeTomcatApi + `purchaseOrder/${storeDB}/deletePurchaseOrder/${orderNumber}`, {
                params: {
                    user: user.Username
                }
            }).catch(err=>errorHandler.tomcatError(err));
        },
        getItemsInPurchaseOrder :async (context , orderNumber)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`purchaseOrder/${storeDB}/getItems/${orderNumber}`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },

        getGrvByNumber :async (context , grvNumber)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`GRV/${storeDB}/${grvNumber}`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },

        getSupplierInfo :async (context , supplierID)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`creditor/${storeDB}/getSupplierInfo`,{ params : {supplierID} })
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },

        checkGateEntrySetting :async ()  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`gateentry/${storeDB}/checkGateEntrySetting`)
                .then(({data})=>data) .catch(err=>errorHandler.tomcatError(err));
        },

        getGateEntryData :async (context , gateEntryID)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`gateentry/${storeDB}/getLines/${gateEntryID}`)
                .then(({data})=>data) .catch(err=>errorHandler.tomcatError(err));
        },

        getIncomingZraInvoices :async (context , supplierID)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`GRV/${storeDB}/getPendingZraInvoices?supplierID=${supplierID}`)
                .then(({data})=>data) .catch(err=>errorHandler.tomcatError(err));
        },

        getAllZraImports :async ()  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`GRV/${storeDB}/getAllZraImports`)
                .then(({data})=>data) .catch(err=>errorHandler.tomcatError(err));
        },
        getZraImportByCode :async (context,code)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`GRV/${storeDB}/getZraImportByCode?code=${code}`)
                .then(({data})=>data) .catch(err=>errorHandler.tomcatError(err));
        },

        getZRAItemsByInvoice :async (context , obj)  =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`GRV/${storeDB}/getZRAItemsByInvoice/${obj.invoiceNumber}?supplierTpin=${obj.tpin}`)
                .then(({data})=>data) .catch(err=>errorHandler.tomcatError(err));
        },

        assignedZraProduct: async (context , payload)=>{
                let user = await Storage.getCurrentUser();
            let storeDB = await Storage.getCurrentStoreDB();
            payload = {
                ...payload,
                created_user: user.Username,
            }
            return axios.post(franchiseTomcatApi + `GRV/${storeDB}/assignProductToCode`, payload)
                .then(()=>context.dispatch(`getIncomingZraInvoices`,payload.supplier_id))
                .catch(err=>errorHandler.tomcatError(err));
        },

        printPurchaseOrder: async (context , orderNumber) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(franchiseApi+`PDFReport/purchaseOrder/${orderNumber}/${storeDB}`)
        },


    }
}