<template>
  <div class="page">
    <div class="cover-header">
      <img alt="" class="img-fluid" src="../assets/logo.png"/>
    </div>
    <h3 class="text-white text-center">Lyt-Soft BackOffice</h3>

  </div>
</template>

<script>

export default {
  name: "RestDeviceCount",
  beforeMount() {
   },
  computed: {

  },
  data() {
    return {

    }
  },
  methods: {
  },}


</script>

<style scoped lang="scss">
body.swal2-shown > [aria-hidden="true"] {
  filter: blur(10px);
}

body > * {
  transition: 0.1s filter linear;
}

.page {
  height: 100vh;
}

.img-fluid {
  height: 250px;
  width: 260px;
  transition: 2s all ease-in-out;
  transform-origin: center;
}

.img-fluid:hover {
  transform: rotate(720deg);
}

.cover-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 50%;
  border-radius: 0 0 15em 15em;
}

@media only screen and (max-height: 750px) {
  .cover-header {
    height: 46%;
  }
}

.login-box {
  position: absolute;
  bottom: 23%;
  right: 50%;
  transform: translate(50%, 38%);
  width: 90vw;
  background: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 1.5em;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
}

.form-group {
  position: relative;
}

.password-input {
  position: relative;
}

.icon {
  position: absolute;
  left: 10px;
  border-right: 1px solid rgba(222, 222, 222, 0.5);
  padding-right: 5px;
  top: 37px;
  color: rgba(200, 200, 200, 0.9);
  font-size: 1.6em;
}

.form-control {
  border: 1px solid #ccc;
  background: rgba(0, 0, 0, 0);
  display: block;
  margin: 5px auto;
  text-align: left;
  padding: 0 40px 0 50px;
  height: 40px;
  border-radius: 1em;
  outline: none;
  color: #a0a0a0;
  transition: ease-in-out 200ms;
}

.form-control:focus {
  background-color: transparent;
  font-weight: 400;
  outline: none;
}

select {
  width: 100%;
}

.login_btn {
  width: 100%;
  height: 40px;
  font-size: 0.8em;
  color: white;
  padding-left: 20px;
  text-transform: uppercase;
  font-weight: 300;
  background: linear-gradient(205deg, #0B2550 0%, #295687 100%);
  border-radius: 1em;
  outline: none;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

label {
  font-style: italic;
  font-weight: 500;
  font-size: 1em;
  color: #6c757d !important;
  margin-bottom: 0;
  margin-left: 20px;
}
</style>
