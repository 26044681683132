import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {Storage} from "@/Services/Storage";
import router from "../../../router";

Vue.use(Vuex);

export default {
    name: 'Securities',
    state: {
        securityGroups: [],
        securities : []
    },
    getters: {
        getSecurityGroups: (state) => state.securityGroups,
        getSecurities: (state) => state.securities
    },
    mutations: {
        setSecurityGroups: (state, payload) => state.securityGroups = payload,
        setAllSecurities: async (state, data) => {
            if (!data) data = await Storage.getAllSecurities();
            else await Storage.setAllSecurities(data);
            state.securities = data;
        },
    },
    actions: {
        getAllSecurityGroups: async (context) => {
            let DB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseApi + `EmpSecurity/getAllSecurityGroups/${DB}`)
                .then(({data}) => context.commit('setSecurityGroups', data))
        },

        async getAllSecurities(context) {
            let store = await Storage.getCurrentStoreDB();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `empsecurity/${store}/getAll`)
                .then(({data}) => context.commit(`setAllSecurities`, data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        getSecuritiesByType: async (context, payload) => {
            let DB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseApi + `EmpSecurity/getByType/${DB}`, {
                params: payload
            }).then(({data}) => data)
        },

        updateSecurity: async (context, payload) => {
            let DB = await Storage.getCurrentStoreDB();
            return axios.put(franchiseApi + `EmpSecurity/${DB}`, payload)
                .then(() => router.push({name: 'employee-security'}))
                .catch(err => errorHandler.tomcatError(err))
        },

        getAutoFloatByGroup: async (context, payload) => {
            let DB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `empsecurity/${DB}/getAutoFloatByGroup?group=`+payload)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        setAutoFloatToGroup: async (context, payload) => {
            let createdBy = await Storage.getCurrentUser();
            createdBy = createdBy.Username;
            payload = { ...payload , createdBy : createdBy }
            let DB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `empsecurity/${DB}/updateAutoFloat`, payload)
                .catch(err => errorHandler.tomcatError(err))
        },

        createSecurityGroup: async (context) => {
            let DB = await Storage.getCurrentStoreDB();
            return Swal.fire({
                titleText :`Security Group Name?`,
                input: 'text',
                inputAutoTrim: true,
                showCancelButton: true,
                confirmButtonText: 'Save',
                showLoaderOnConfirm: true,
                preConfirm: (inputValue) => {
                    if (!inputValue) return Swal.showValidationMessage(`Security Group Name Required`);
                    return axios.post(franchiseApi + `EmpSecurity/${DB}`, {
                        groupName: inputValue
                    }).then(() => context.dispatch(`getAllSecurityGroups`))
                        .catch(error => {
                            let err = errorHandler.tomcatError(error, true)
                            Swal.showValidationMessage(`Request failed: ${err}`);
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) responses.showSuccess(`Security Group Created Successfully`)
            })
        },

        validateSecurityPermissionNoPrompt: async (context, security) => {
            let securities = await Storage.getAllSecurities();
            let user = await Storage.getCurrentUser();
            let userType = user.Type;
            let group = securities.find(sec => sec.GroupName.toLowerCase() === userType.toLowerCase());
            if (!group) {
                await responses.showWarning(`No security group found`)
                return false;
            }
            return group[security];
        },
        validateSecurityPermission: async (context, security) => {
            let securities = await Storage.getAllSecurities();
            let user = await Storage.getCurrentUser();
            let userType = user.Type;
            let group = securities.find(sec => sec.GroupName.toLowerCase() === userType.toLowerCase());
            if (!group) {
                await responses.showWarning(`No security group found`)
                return false;
            }
            if (!group[security]) {
                return Swal.fire({
                    title: `User not Allowed to ${security}`,
                    icon : "warning",
                    text : `Input supervisor password \n To Allow ${user.Username} to ${security}`,
                    input: "password",
                    showCloseButton : true,
                    showCancelButton: true,
                    confirmButtonText: "Authorize",
                    showLoaderOnConfirm : false,
                    preConfirm: async (password) => {
                        let users = await Storage.getAllUsers();
                        let supervisor = users.find(user => user.Password.toLowerCase() === password.toLowerCase());
                        if (!supervisor) {
                            Swal.showValidationMessage(`Incorrect password`)
                            return false;
                        }

                        let supervisorType = supervisor.Type;
                        let supervisorGroup = securities.find(sec => sec.GroupName.toLowerCase() === supervisorType.toLowerCase());
                        if (!supervisorGroup[security]) {
                            Swal.showValidationMessage(`User ${supervisor.Username} not allowed to authorize ${security}`)
                            return false;
                        }
                        let storeDB = await Storage.getCurrentStoreDB();
                        axios.post(serverApi+`supervisorOverrides/${storeDB}`,{
                            Supervisor : value.supervisor,
                            Cashier : value.cashier,
                            Reason : security,
                        })
                        return {
                            allowed : true,
                            supervisor : supervisor.Username,
                            cashier : user.Username,

                        };
                    },
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let value = result.value;
                        if (value.allowed === true){
                            let storeDB = await Storage.getCurrentStoreDB();
                            axios.post(serverApi+`supervisorOverrides/${storeDB}`,{
                                Supervisor : value.supervisor,
                                Cashier : value.cashier,
                                Reason : security,
                            })
                        }
                        return value;
                    }
                    return false;
                });

            }
            if (security.toString().toLowerCase().includes(`void`)){
                return {supervisor : user.Username}
            }
            return true;
        },


    }
};
