<template>
  <div class="task-container">
    <div class="task-item">
      <div class="task-header">
        <div>
          <a v-if="note.createduser" class="task-title">{{
              `${note.createduser.firstName} ${note.createduser.lastName}`
            }}</a>
          <a v-else class="task-title"><img alt="" src="/img/user.jpg">Customer</a>
          <span class="task-subtitle">   {{ $dateTimeManager.shortenStandardDisplay(note.createdDate) }}</span>
        </div>
        <div class="btn-group" role="group">
          <button id="btnGroupDrop1" aria-expanded="false" class="btn dropdown-toggle" data-bs-toggle="dropdown">
            <font-awesome-icon icon="fa-solid fa-ellipsis"/>
          </button>
          <ul aria-labelledby="btnGroupDrop1" class="dropdown-menu">
            <li><a v-if="canEditNote" class="dropdown-item" @click="edit(note)">Edit</a></li>
            <li><a class="dropdown-item" @click="reply(note)">Reply</a></li>
          </ul>
        </div>
      </div>
      <div class="note-body">
        <div v-html="note.Note"></div>
      </div>
      <span v-if="note.employee" class="reply-text">Sent To : {{ repliedUsername }} </span>
    </div>
    <div v-if="note.replies" class="note-replies">
      <TicketNoteListItem v-for="reply in note.replies" :key="reply.id" :is-reply="true"
                          :note="reply" :ticket-id="ticketId"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketNoteListItem",
  props: {
    isReply: {
      type: Boolean,
      default: false
    },
    ticketId: {
      required: true,
      type: Number
    },
    note: {
      type: Object,
      required: true
    }
  },
  computed: {
    repliedUsername() {
      let user = this.$store.getters.getAllUsers
          .find(technician => technician.id === this.note.employee);
      if (!user && !this.note.internal) {
        return `Customer`;
      } else if (!user && this.note.internal) {
        return `N/A`;
      }
      return user.firstName + ` ` + user.lastName;
    },
    noteUser() {
      return this.note.createduser;
    },
    current_user() {
      return this.$store.getters.getCurrentUser;
    },
    canEditNote() {
      if (!this.noteUser) {
        return false;
      }
      return this.noteUser.id === this.current_user.id;
    }
  },
  methods: {
    edit(note) {
      this.$dateTimeManager.timeAgo(note.createdDate)
      return this.$store.dispatch('editTicketNote', note)
    },
    reply(note) {
      this.$store.dispatch('replyToNote', note)
    },
  }
}
</script>

<style lang="scss" scoped>
img, svg {
  vertical-align: middle;
  width: -webkit-fill-available;
}

.task-container {
  .task-item {
    position: relative;
    border: 1px solid #ddd6d6;
    border-radius: 5px;
    padding: 10px;

    .dot {
      width: 20px;
      height: 20px;
      border: 2px solid #34373C;
      border-radius: 50%;
    }

    .task-header {
      border-bottom: 1px solid #a1a1a1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .task-subtitle {
        font-size: 13px;
        color: #505050;
        font-weight: 300;
      }

      .btn {
        border: none;

        &:active, &:hover {
          border: none;
          background: rgb(11 102 255 / 20%);
        }
      }

      .task-title {
        font-weight: 600;
        color: #212D3D;
      }
    }
  }

  .note-replies {
    border-left: #d7d7d9 2px solid;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

img {
  height: 20px;
  width: 20px
}

.reply-text {
  font-size: 12px;
}
</style>