<template>
  <page-with-back-btn :title="`Tickets #${id}`">
    <div class="d-flex">
      <div class="m-3 flex-grow-1">
        <h4>{{ ticket.title }}</h4>
        <hr/>
        <div>
          <p>Status : <span :class="`badge `+(ticket.status===`CLOSED`?`bg-success`:`bg-warning`)">{{ ticket.status }}</span></p>
          <p class="mb-0">Store : {{ ticket.store ? ticket.store : `No Store Specified` }}</p>
          <line-separator title="Ticket Info"/>
          <p class="mb-0">Type : {{ ticket.category }}</p>
          <p class="mb-0">Sub-Type : {{ ticket.subCategory }}</p>
          <p class="mb-0">Assigned To : {{ ticket.assignedTo }}</p>
          <p class="mb-0" v-if="ticket.createdDate">Reported Date : {{ $utils.displayDate(ticket.createdDate , true) }}</p>
        </div>
        <div v-if="ticket.description">
          <line-separator title="Ticket Description"/>
          <div v-html="ticket.description"></div>
        </div>
        <hr/>
      </div>
    </div>

    <!--    <floating-add-button icon="pen-to-square" @click="editTicket" v-if="ticket.status !== `CLOSED`"/>-->

    <div class="p-3">
      <nav>
        <div id="nav-tab" class="nav nav-tabs" role="tablist">
          <button id="ticket-notes-tab" aria-controls="ticket-notes" aria-selected="false" class="nav-link active"
                  data-bs-target="#ticket-notes"
                  data-bs-toggle="tab" role="tab" type="button">
            <font-awesome-icon icon="fa-solid fa-comments"/>
            Notes
          </button>

          <button id="ticket-attachment-tab" aria-controls="ticket-attachment" aria-selected="false"
                  class="nav-link"
                  @click="getAttachments"
                  data-bs-target="#ticket-attachment"
                  data-bs-toggle="tab" role="tab" type="button">
            Attachment
          </button>
        </div>
      </nav>
      <div id="nav-tabContent" class="tab-content">
        <div id="ticket-notes" aria-labelledby="ticket-notes-tab" class="tab-pane fade show active" role="tabpanel">
          <TicketNotesList :ticketID="Number(id)"/>
        </div>

        <floating-add-button icon="pen-to-square" @click="editTicket" v-if="ticket.status !== `CLOSED`"/>

        <div id="ticket-attachment" aria-labelledby="ticket-attachment-tab" class="tab-pane fade" role="tabpanel">
          <div v-if="attachments.length === 0" class="no-attachments">
            <div class="no-attachments-content">
              <p>No attachments available</p>
            </div>
          </div>
          <div v-else>
            <ul>
              <li v-for="attach in attachments"
                  class="attachment-item card d-flex flex-row justify-content-between mb-3"
                  @dblclick="openFile(attach.id)">

                <div class="attachment-info d-flex flex-row align-items-center">
                  <font-awesome-icon class="file-icon mx-2" :icon="getFileIcon(attach.type)" />
                  <div class="file-details d-flex flex-column">
                    <span class="file-name">{{ attach.name }}</span>
                    <span class="file-type text-sm text-secondary">{{ attach.type }}</span>
                  </div>
                </div>

                <div class="action-buttons d-flex flex-column justify-content-between p-3">
                  <span class="text-sm text-secondary">Double Click To Download</span>
                  <div class="button-group d-flex flex-row justify-content-start">
                    <button class="btn btn-outline-primary btn-sm" @click="openFile(attach.id)">Download</button>
                    <button class="btn btn-danger btn-sm mx-2" @click="deleteFile(attach.id)">
                      <font-awesome-icon icon="trash" />
                    </button>
                  </div>
                </div>

                <div class="attachment-meta d-flex flex-column justify-content-between ">
                  <span class="text-sm text-secondary">
                    Attached By: {{ attach.attached_by }}
                  </span>
                </div>
              </li>
            </ul>

          </div>
          <floating-add-button @click="addFile"/>
          <input type="file" ref="addFile" style="display: none" @change="uploadAttachment">
        </div>
      </div>
    </div>


  </page-with-back-btn>
</template>

<script>
import FullPage from "@/components/layout/utils/FullPage.vue";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton.vue";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn";
import LineSeparator from "@/components/layout/utils/LineSeparator";
 import {utils as $utils} from "../../Services/utils";
 import TicketNotesList from "@/components/notes/TicketNotesList.vue";

export default {
  name: "TicketDetails",
  components: {TicketNotesList,LineSeparator, PageWithBackBtn, FullPage, FloatingAddButton},
  beforeMount() {
    this.getTicketDetails();
  },
  data() {
    return {
      ticket: {},
      attachments: [],
      id: this.$route.params.id,
      isEditing: false,
      editableRow: [],
      // userComment: '',
      showTicketDetails: false
    };
  },
  computed: {
    filteredNotes() {
      // Return only notes where internal is false
      return this.notes.filter(note => note.internal === false);
    },
    $utils() {
      return $utils
    }
  },
  methods: {
    addFile() {
      return this.$refs.addFile.click();
    },
    deleteFile(id) {
      this.$utils.showLoading()
      return this.$store.dispatch(`deleteDocumentFromTicket`, {
        ticket: this.$route.params.id,
        attachmentID: id,
      }).then(() => this.getFiles()).finally(() => this.$utils.hideLoading())
    },
    copyToClipboard() {
      const ticketType = this.ticketType(); // Call the ticketType method

      const ticketInfo = {
        ticketNumber: this.ticket.id,
        customer: this.storeName,
        tickettype: ticketType ? ticketType.name : "Unknown", // Use ticketType.name or a fallback value
        assignedTo: this.ticket.assignedUser.Username ,
        createdDate: this.ticket.createdDate,
        title: this.ticket.title,
        storeContact: this.storeContact, // Correctly access computed property
      };
      console.log('Copy to Clipboard:', ticketInfo.storeContact);
      // Create a formatted string for the clipboard
      const ticketInfoString = `
    Ticket #${ticketInfo.ticketNumber}
    Customer: ${ticketInfo.customer}
    Type: ${ticketInfo.tickettype}
    Assigned To: ${ticketInfo.assignedTo}
    Created Date: ${ticketInfo.createdDate}
    Store Contact: ${ticketInfo.storeContact}

    Summary
    ${ticketInfo.title}
  `.trim();

      // // Create a temporary textarea element to hold the formatted string
      // const tempTextarea = document.createElement("textarea");
      // tempTextarea.value = ticketInfoString;
      // document.body.appendChild(tempTextarea);
      //
      // // Select the content and copy it to the clipboard
      // tempTextarea.select();
      // document.execCommand("copy");
      //
      // // Remove the temporary textarea element
      // document.body.removeChild(tempTextarea);
      copy(ticketInfoString)
      responses.showSuccess(`Copied Internal Ticket info to Clipboard`)
    },
    async uploadAttachment(event) {
      const file = event.target.files[0];
      if (file) {
        let type = file.type;
        let attachment = await getBase64(file);
        attachment = this.stripMimeType(attachment);
        let fullFileName = file.name;
        let name = fullFileName.substring(0, fullFileName.lastIndexOf('.')) || fullFileName;

        let ticketID = this.$route.params.id;
        let obj = {name, attachment, type, ticketID}
        this.$utils.showLoading()
        return this.$store.dispatch(`addDocumentToTicket`, obj)
            .then(() => this.getFiles())
            .finally(() => this.$utils.hideLoading())
      }
    },
    getFileIcon(type) {
      switch (type) {
        case 'application/pdf':
          return `print`;
        case 'text/csv':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return `table`;
        case `image/jpeg`:
        case `image/png`:
        case `image/svg+xml`:
        case `image/bmp`:
        case `image/webp`:
        case `image/x-windows-bmp`:
          return `image`;
        default:
          return `file`;
      }
    },
    stripMimeType(base64) {
      const pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
      return base64.replace(pattern, '');
    },
    getAttachments() {
      this.$utils.showLoading();
      return this.getFiles().finally(() => this.$utils.hideLoading());
    },
    getFiles() {
      let ticketID = this.$route.params.id;
      return this.$store.dispatch(`getAttachmentsByTicket`, ticketID)
          .then(res => this.attachments = res)
    },
    startEditing(row) {
      this.isEditing = true;
      this.editableRow = row.details.map(detail => detail.value); // Populate editableRow with current values
    },
    editTicket() {
      return this.$router.push({
        name: 'CreateTicket',
        query: {id: this.id}
      });
    },
    saveChanges(row) {
      this.isEditing = false;
      row.details = row.details.map((detail, index) => ({
        ...detail,
        value: this.editableRow[index]
      }));
    },
    openNotesModal(expenseId) {
      // Implement logic to open notes modal
      console.log('Open notes for expense with ID:', expenseId);
    },
    openAttachmentDialog(expenseId) {
      // Implement logic to open file attachment dialog
      console.log('Open attachment dialog for expense with ID:', expenseId);
    },
    toggleTicketDetails() {
      this.showTicketDetails = !this.showTicketDetails;
    },
    openFile(id) {
      return this.$store.dispatch(`openAttachment`, {
        ticket: this.$route.params.id,
        attachmentID: id,
      })
    },
    getTicketDetails() {
      this.$utils.showLoading();
      return this.$store.dispatch(`getTicketDetails`, this.id)
          .then((res) => this.ticket = res)
          .finally(() => this.$utils.hideLoading())
    },
  },
};
</script>


<style scoped>
ul {
  padding: 0;
  list-style-type: none;
}

.attachment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
}

.attachment-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}

.file-icon {
  font-size: 24px;
  color: #4e73df;
}

.file-details {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-weight: 600;
  font-size: 14px;
  color: #212D3D;
}

.file-type {
  font-size: 12px;
  color: #505050;
}

.action-buttons {
  text-align: right;
}

.button-group button {
  font-size: 12px;
}

.button-group .btn-outline-primary {
  border: 1px solid #4e73df;
}

.attachment-meta {
  font-size: 12px;
  color: #505050;
  margin-right: 10px;
}
.d-flex {
  display: flex;
  align-items: center;
}

.card {
  margin: 0;
  padding: 0;
}

.card-header {
  border-bottom: none;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.comment-section {
  display: flex;
  align-items: center;
}

.comment-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.file-upload-section {
  margin-top: 1rem;
}

.file-upload-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.file-upload-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}
</style>