import {Storage} from "@/Services/Storage";
import axios from "axios";
import router from "../../../router";

export default {
    name: 'Stock',
    state: {
        stockValue: 0,
        itemsBelowMin: 0,
        itemsReceivedRecently: 0,
        stock: [],
        supportedCountries: [],
    },
    getters: {
        getStockValue: (state) => state.stockValue,
        getItemsBelowMin: (state) => state.itemsBelowMin,
        getItemsReceivedRecently: (state) => state.itemsReceivedRecently,
        getStock: (state) => state.stock,
        getZraSupportedCountries: (state) => state.supportedCountries,
    },
    mutations: {
        clearStock: async (state) => {
            await Storage.setStock([])
            state.stock = [];
        },
        setAllStock: async (state, payload) => {
            if (payload) await Storage.setStock(payload)
            else payload = await Storage.getAllStock();
            state.stock = payload;
        },
        setStockValue: (state, payload) => state.stockValue = payload,
        setItemsBelowMin: (state, payload) => state.itemsBelowMin = payload,
        setItemsReceivedRecently: (state, payload) => state.itemsReceivedRecently = payload,
        setZraSupportedCountries: (state, payload) => state.supportedCountries = payload,
    },
    actions: {
        countItemsBelowMin: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `generalStockStats/${storeDB}/countItemsBelowMin`)
                .then(({data}) => context.commit(`setItemsBelowMin`, data))
                .catch(err => errorHandler.tomcatError(err))
        },

        getStockValue: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `generalStockStats/${storeDB}/getStockValue`)
                .then(({data}) => context.commit(`setStockValue`, data))
                .catch(err => errorHandler.tomcatError(err))
        },
        openStockValueReport: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(franchiseApi + `PDFReport/StockValue/${storeDB}`);
        },

        countItemsRecentlyReceived: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `generalStockStats/${storeDB}/countItemsRecently`)
                .then(({data}) => context.commit(`setItemsReceivedRecently`, data))
                .catch(err => errorHandler.tomcatError(err))
        },
        getAllStock: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(backOfficeTomcatApi + `stockUtils/${storeDB}/StockSearch`, payload)
                .then(({data}) => {
                    context.commit(`setAllStock`, data)
                    return data;
                }).catch(err => errorHandler.tomcatError(err))
        },

        printPriceList: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(printApi + `Stock/${storeDB}/priceList`)
        },

        syncStockToZra: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `stock/${storeDB}/syncStockToZra`)
                .then(() => responses.showSuccess(`Items Synced to Smart Invoicing Server`))
                .catch(err => errorHandler.tomcatError(err))
        },

        massUpdatePrices: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.put(franchiseTomcatApi + `createItem/${storeDB}/massUpdate`, payload)
                .then(() => responses.showSuccess(`Prices Updated`))
                .catch(err => errorHandler.tomcatError(err))
        },
        addStockItem: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            return axios.post(franchiseTomcatApi + `createItem/${storeDB}?user=`+user.Username, payload)
                .catch(err => errorHandler.tomcatError(err))
        },

        sendPriceToScale: async (context, payload) => {
            return axios.post(hardwareApi + `utils/setScale`, payload)
        },

        validateItemCode: async (context, plu) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `generalStockStats/${storeDB}/validatePlu`, {
                params: {plu: plu}
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        getItemImage: async (context, plu) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(stocklistApi2 + `stockImage/${storeDB}/getImage?PLU=` + plu)
                .then(({data}) => data);
        },
        getqty: async (context, plu) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(serverApi + `stock/${storeDB}/getAvailableQty?plu=` + plu)
                 .then(({data}) => data);
        },
        getItemRateOfSale: async (context, plu) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `stockUtils/${storeDB}/getRateOfSale?plu=` + plu)
                .then(({data}) => data);
        },
        getWeeksOfStock: async (context, plu) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `stockUtils/${storeDB}/getWeeksOfStock?plu=` + plu)
                .then(({data}) => data);
        },

        getLabelPrintData: async (context, obj) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `stockUtils/${storeDB}/getLabelPrintData?plu=` + obj.plu+`&copies=`+obj.copies)
                .then(({data}) => {
                  let payload = {
                      printer : obj.printer,
                      printData : data
                  }
                  return context.dispatch(`printLabels`,payload)
                }).catch(err=>errorHandler.tomcatError(err))
        },
        printLabels: async (context, obj) => {
            return axios.post(hardwareApi + `printing/label/${obj.printer}`,obj.printData)
                .catch(err=>errorHandler.tomcatError(err))
        },
        setItemImage: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(stocklistApi2 + `stockImage/${storeDB}/addImage`, payload)
                .catch((err) => errorHandler.tomcatError(err, false, `Could not Add Image`));
        },
        changeCode1: (context, plu) => {
            return Swal.fire({
                title: 'Change Debtor Id',
                icon: 'info',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: 'Save',
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                preConfirm: async (input) => {
                    if (!input) return Swal.showValidationMessage(`New Code required`)
                    return context.dispatch('updateCodeApi1', {
                        oldID: plu,
                        newID: input,
                    }).then(() => input)
                        .catch(({message}) => Swal.showValidationMessage(`Request failed: ${message}`))
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire({
                        title: `Success`,
                        icon: 'success',
                        text: `Debtor Code ${result.value} Saved`
                    }).then(() => {
                        return router.replace({
                            name: 'debtor-update',
                            query: {id: result.value}
                        }).then(() => window.location.reload(true));
                    })
                }
            })
        },

        changeCode: (context, plu) => {
            return Swal.fire({
                title: 'Change Stock Code / Barcode',
                icon: 'info',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: 'Save',
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                preConfirm: async (input) => {
                    if (!input) return Swal.showValidationMessage(`New Code required`)
                    return context.dispatch('updateCodeApi', {
                        'oldPLU': plu,
                        'newPLU': input,
                    }).then(() => input)
                        .catch(({message}) => Swal.showValidationMessage(`Request failed: ${message}`))
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire({
                        title: `Success`,
                        icon: 'success',
                        text: `Stock Code ${result.value} Saved`
                    }).then(() => {
                        return router.replace({
                            name: 'stock-edit',
                            query: {plu: result.value}
                        }).then(() => window.location.reload(true));
                    })
                }
            })
        },

        updateCodeApi: async (context, data) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(franchiseTomcatApi + `stock/${storeDB}/updatePLU`, data)
                .catch((err) => errorHandler.tomcatError(err, true))
        },
        updateCodeApi1: async (context, data) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(serverApi + `debtor/${storeDB}/updateID`, data)
                .catch((err) => errorHandler.tomcatError(err, true))
        },

        getItemByPLU: async (context, plu) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `stock/${storeDB}/${plu}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        getZraSupportedCountries: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `currency/maindb/getZraSupportedCountries`)
                .then(({data}) => context.commit(`setZraSupportedCountries`,data))
                .catch(err => errorHandler.tomcatError(err))
        },
        miniSearchStock: async (context, search) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(onlineSalesTomcatApi + `stock/${storeDB}/search?q=${search}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        getOrderLocations: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(onlineSalesTomcatApi + `orders/${storeDB}/getOrderLocations`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        adjustStock: (context, item) => {
            return Swal.fire({
                allowOutsideClick: false,
                showCancelButton: true,
                showLoaderOnConfirm: true,
                title: `Adjust Qty`,
                html: `<h6 >Description : ${item.Description}</h6>
               <h6 >Current QTY : ${item.QTY}</h6>
               <select name="adjustment-type" class="swal2-input" id="adjustment-type" style="max-width: 80%">
               <option selected value="1">Remove Stock(Damaged)</option>
               <option value="0">Add Stock Qty(Miscount)</option>
               <option value="2">Set New Level</option>
               </select>
               <input type="number" step="0.001" id="qty" class="swal2-input" placeholder="Adjustment Qty" style="max-width: 70%">
               
               <input type="text" id="adj-reason" class="swal2-input" placeholder="Reason" style="max-width: 70%">`,
                confirmButtonText: 'Adjust Qty',
                focusConfirm: false,
                preConfirm: async () => {
                    let new_qty = Swal.getPopup().querySelector('#qty').value
                    if (new_qty === null || new_qty === undefined || new_qty === '') {
                        return Swal.showValidationMessage(`Please enter a valid Adjustment Quantity`)
                    }

                    let reason = Swal.getPopup().querySelector('#adj-reason').value
                    if (reason === null || reason === undefined || reason === '') {
                        return Swal.showValidationMessage(`Please enter a valid Reason`)
                    }

                    let type = Swal.getPopup().querySelector('#adjustment-type').value
                    let user = await Storage.getCurrentUser();
                    let data = {
                        user: user.Username,
                        pc: 'Online',
                        plu: item.PLU,
                        reason: reason,
                        qty: parseFloat(new_qty),
                        adjustmentType: parseFloat(type),
                    };
                    return context.dispatch('processAdjustment', data)
                        .then(() => data)
                        .catch(err => Swal.showValidationMessage(err))
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire(
                        'Done!',
                        `${item.Description} quantity has been adjusted successfully.`,
                        'success'
                    );
                }
            })
        },
        processAdjustment: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(serverApi + `stock/${storeDB}/adjustment`, payload)
                .catch(err => errorHandler.tomcatError(err));
        },

        processConversion: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post( backOfficeTomcatApi + `stockUtils/${storeDB}/newConvertion`, payload)
                .then(() => {
                    responses.showSuccess(`Stock Converted Successfully`)
                    return router.push({name: 'stock'})
                }).catch(err => errorHandler.tomcatError(err));
        },
        getQtyInFranchise: async (context, plu) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `stock/${storeDB}/getQtyInFranchise?plu=${plu}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        getZraSupportedUnitsOfMeasure: () => {
            return axios.get(franchiseTomcatApi + `stock/maindb/getZraSupportedUnitsOfMeasure`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        getZraSupportedPackaging: () => {
            return axios.get(franchiseTomcatApi + `stock/maindb/getZraSupportedPackaging`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        massArchiveStock: async (context, selectedPLUs) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(backOfficeTomcatApi + `stockUtils/${storeDB}/massArchive`, selectedPLUs)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
    }
}
