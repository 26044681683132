<template>
  <div class="page">
    <button class="btn btn-primary" @click="logOutOfFranchise">
      <font-awesome-icon icon="fa-solid fa-lock" />
      Log Out
    </button>

    <div class="cover-header">
      <img alt="" class="img-fluid" src="../assets/logo.png" />
    </div>
    <h3 class="text-white text-center">Lyt-Soft BackOffice</h3>
    <form @submit.prevent="userLogin" class="login-box">
      <div class="col-12 text-center text-secondary">
        <h6>Chrilan Support Verification</h6>
      </div>

      <div class="mb-2">
           <v-select
              v-model="selectedStaff"
              :options="staffList"
              :reduce="staff => staff.id"
              label="username"
           placeholder="Select Staff"
              required
          ></v-select>
       </div>
      <button type="submit" class="login_btn" :disabled="!selectedStaff">Login</button>
    </form>
  </div>
</template>

<script>
import { Storage } from "@/Services/Storage";
import Swal from "sweetalert2";
import axios from "axios";
import vSelect from "vue-select"; // Ensure Vue Select is installed: npm install vue-select

export default {
  name: "verifySupportLogin",
  components: { vSelect },
  data() {
    return {
      selectedStaff: null, // Holds the selected staff member's ID
      staffList: [], // List of staff fetched from the API
    };
  },
  methods: {
    async fetchSupportStaff() {
      this.$utils.showLoading();
      try {
        const response = await axios.get(ticketApi+"support/getActiveSupportStaff");
        this.staffList = response.data; // Assign the returned staff data to the staffList
      } catch (error) {
        console.error("Error fetching staff list:", error);
        Swal.fire("Error", "Unable to fetch staff list", "error");
      }finally {
        // Ensure loading spinner is hidden
        this.$utils.hideLoading();
      }
    },
    async userLogin() {
      this.$utils.showLoading();

      if (this.selectedStaff) {
        try {
          // Fetch the current store database name
          let storedb = await Storage.getCurrentStoreDB();

          // Get the selected user's full details
          const selectedStaffDetails = this.staffList.find(
              staff => staff.id === this.selectedStaff
          );

          if (!selectedStaffDetails) {
            Swal.fire("Error", "Selected staff not found", "error");
            return;
          }

          // Append "(Support)" to the selected user's name
          const supportLoggedUser = `${selectedStaffDetails.username} (Support)`;

          // Save the user information in local storage
          localStorage.setItem("Supportlogged in user", supportLoggedUser);

          // Construct the payload
          const payload = {
            userID: this.selectedStaff,
            storeDB: storedb,
          };

          // Log the payload for debugging purposes
          console.log("Payload to send:", payload);

          // Make a POST request to the 'support/requestOTP' endpoint
          const response = await axios.post(ticketApi + "support/requestOTP", payload);

          // Handle the response
          console.log("OTP request successful:", response.data);
          Swal.fire("Success", "OTP requested successfully", "success");

          // Redirect to the 'supportotp' page
          this.$router.push({ name: "SupportOtp" });
        } catch (error) {
          console.error("Error during OTP request:", error);
          Swal.fire("Error", "OTP request failed. Please try again.", "error");
        } finally {
          // Ensure loading spinner is hidden
          this.$utils.hideLoading();
        }
      } else {
        Swal.fire("Validation", "Please select a staff member", "warning");
      }
    },

    logOutOfFranchise() {
      return Swal.fire({
        title: "Are you sure?",
        text: "You are logging out of the Current Franchise",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#dd3",
        confirmButtonText: "Yes, Log Out!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$utils.showLoading();
          let deviceID = await Storage.getDeviceID();
          localStorage.removeItem("Supportlogged in user");

          localStorage.clear();
          await Storage.setDeviceID(deviceID);
          await this.$router.push({ name: "login" });
          return window.location.reload(true);
        }
      });
    },
  },
  mounted() {
    this.fetchSupportStaff(); // Fetch the staff list when the component is mounted
  },
};
</script>

<style>
@import "vue-select/dist/vue-select.css"; /* Include Vue Select styles */
</style>

<style>
body.swal2-shown > [aria-hidden="true"] {
  filter: blur(10px);
}

body > * {
  transition: 0.1s filter linear;
}
</style>

<style lang="scss" scoped>
.page {
  height: 100vh;
}

.img-fluid {
  height: 250px;
  width: 260px;
  transition: 2s all ease-in-out;
  transform-origin: center;

  & :hover {
    transform: rotate(720deg);
  }
}

.cover-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 50%;
  border-radius: 0 0 15em 15em;
  /*background-image: url("../assets/images/bg.png");*/
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-height: 750px) {
  .cover-header {
    height: 46%;
  }
}

.login-box {
  position: absolute;
  bottom: 23%;
  right: 50%;
  transform: translate(50%, 38%);
  width: 90vw;
  background: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 1.5em;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);

  .form-group {
    position: relative;

    .icon {
      position: absolute;
      left: 10px;
      border-right: 1px solid rgba(222, 222, 222, 0.5);
      padding-right: 5px;
      top: 37px;
      color: rgba(200, 200, 200, 0.9);
      font-size: 1.6em;
    }
  }

  .form-control {
    border: 1px solid #ccc;
    background: rgba(0, 0, 0, 0);
    display: block;
    margin: 5px auto;
    text-align: left;
    padding: 0 40px 0 50px;
    height: 40px;
    border-radius: 1em;
    outline: none;
    color: #a0a0a0;
    transition: ease-in-out 200ms;
  }

  .form-control:focus {
    background-color: transparent;
    font-weight: 400;
    outline: none;
  }

  select {
    width: 100%;
  }

  .login_btn {
    width: 100%;
    height: 40px;
    font-size: 0.8em;
    color: white;
    padding-left: 20px;
    text-transform: uppercase;
    font-weight: 300;
    background: linear-gradient(205deg, #0b2550 0%, #295687 100%);
    border-radius: 1em;
    outline: none;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  label {
    font-style: italic;
    font-weight: 500;
    font-size: 1em;
    color: #6c757d !important;
    margin-bottom: 0;
    margin-left: 20px;
  }
}
</style>
