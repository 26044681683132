<template>
  <vue-drawer-layout
      ref="drawer"
      :drawer-width="300"
      :enable="false"
      @mask-click="closeDrawer">
    <div slot="drawer">
      <NavigationDrawer :close="closeDrawer" class="navigation-drawer"/>
    </div>
    <div slot="content" class="root-pane">
      <div>
        <NetworkStatus />
      </div>
      <NavigationBar :toggle="openDrawer" class="nav"/>
      <div class="navigation-route-view">
        <router-view/>
      </div>
    </div>
  </vue-drawer-layout>
</template>

<script>
import NavigationBar from "./navigation/NavigationBar.vue";
import NavigationDrawer from "./navigation/NavigationDrawer.vue";
import NetworkStatus from "@/components/layout/NetworkStatus.vue";

export default {
  name: "Layout",
  components: {NavigationDrawer, NavigationBar, NetworkStatus},
  methods: {
    openDrawer() {
      this.$refs.drawer.toggle();
    },
    closeDrawer() {
      this.$refs.drawer.toggle(false);
    },
  }

}
</script>

<style lang="scss" scoped>
.root-pane {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.navigation-drawer {
  min-width: 285px;
  width: 100%;
  height: 100vh;
}

.navigation-route-view {
  transition: 0.5s ease;
}

.offline-banner {
  position: sticky;
  top: 0;
  left: 0;
  padding: 5px;
  display: block;
  text-align: center;
  width: 100%;
  background: rgb(220, 53, 69);
  color: white;
  right: 0;
}
</style>
